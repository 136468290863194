import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

/* import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar'; */

import ButtonsSpeechAboutFR from './ButtonsSpeechAboutFR';
import ButtonsSpeechAboutEN from './ButtonsSpeechAboutEN';

import { useTranslation, Trans } from 'react-i18next';
import '../js/i18n';

import Aos from "aos";
import 'aos/dist/aos.css';

import gsap from 'gsap';
import Donation from './Donation';
import Credits from './Credits';
import ButtonBack from './ButtonBack';

const AboutSection = () => {

    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const { t } = useTranslation();

    useEffect(() => {
        Aos.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
        });
    })

    return (

        <main>

            <div className="about__container">

                <div className="about__title" data-aos="fade">
                    <p>{t('about-title')}</p>
                </div>

                <article className="about__text backdrop" data-aos="fade">

                    {currentLanguage === 'en' ? <ButtonsSpeechAboutEN /> : <ButtonsSpeechAboutFR />}

                    <br /><br /> <hr /> <br /> <br />

                    <p data-aos="fade">{t('about-text-01')}</p><br />
                    <p className="italic" data-aos="fade">{t('about-text-02')}</p><br />
                    <p data-aos="fade">{t('about-text-03')}</p><br />
                    <p data-aos="fade">{t('about-text-04')}</p><br />
                    <p className="italic" data-aos="fade">{t('about-text-05')}</p><br />
                    <p data-aos="fade">{t('about-text-06')}</p><br />
                    <p data-aos="fade">{t('about-text-07')}</p><br /><br />
                    <p data-aos="fade">{t('about-text-08')}</p><br />
                    <p className="italic" data-aos="fade">{t('about-text-09')}</p><br />
                    <p data-aos="fade">{t('about-text-10')}</p><br />
                    <p data-aos="fade">{t('about-text-11')}</p><br />
                    <p data-aos="fade">{t('about-text-12')}</p><br />
                    <p data-aos="fade">{t('about-text-13')}</p><br />
                    <p data-aos="fade">{t('about-text-14')}</p><br />
                    <p className="italic" data-aos="fade">
                        <Trans i18nKey="about-text-15" components={{ br: <br /> }} />
                    </p><br />

                    <hr /><br /><br />

                    <p className="text__small" data-aos="fade">¹ <a href="https://www.ecowatch.com/biomass-humans-animals-2571413930.html"
                        target="_blank" rel="noreferrer">www.ecowatch.com/biomass-humans-animals-2571413930.html</a><br />
                        ² <a href="https://www.pnas.org/doi/10.1073/pnas.1711842115"
                            target="_blank" rel="noreferrer">www.pnas.org/doi/10.1073/pnas.1711842115</a>
                    </p>
                </article>


                <picture>
                    <source className="picture" srcSet="/content/pictures/bundle/webp/USB-Card-Recto.webp" type="image/webp" />

                    <img className="picture" src="/content/pictures/bundle/jpeg/USB-Card-Recto.jpg" loading="lazy"
                        alt="FLESHTV cow nosering" width="1000" height="627" />
                </picture>

                {/*   <div>
                    <h1>PayPal Checkout</h1>
                    <PaypalButton amount="10.00" onSuccess={handleSuccess} />
                </div> */}

                <Donation />
                {/* 
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        effect={'fade'}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation, Effect]}
                    >
                        <SwiperSlide>
                            <img src="/content/pictures/bundle/jpeg/USB-Card-Recto.jpg"
                                alt="FLESHTV cow nosering" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="/content/pictures/bundle/jpeg/USB-Card-Verso.jpg"
                                alt="FLESHTV cow nosering" />
                        </SwiperSlide>
                    </Swiper>
 */}

                <picture>
                    <source className="picture" srcSet="/content/pictures/bundle/webp/USB-Card-Verso.webp" type="image/webp" />
                    <img className="picture" src="/content/pictures/bundle/jpeg/USB-Card-Verso.jpg" loading="lazy"
                        alt="FLESHTV cow nosering" width="1000" height="627" />
                </picture>

                <Credits />
                {/*   <ButtonBack /> */}


            </div>


        </main>


    );
}

export default AboutSection;