import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import '../js/i18n';

import gsap from 'gsap';

const ButtonBack = () => {
    const navigate = useNavigate();


    useEffect(() => {
        const handlePopState = () => {
            handleClick();
            console.log('Back button was pressed!');
        };

        window.addEventListener('popstate', handlePopState);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);


    const handleClick = () => {

        window.speechSynthesis.cancel();

        //fade in

        gsap.to('.button__fleshtv', { autoAlpha: 1, zIndex: 1001, duration: 0.5, delay: 2, ease: "power3.inOut" })
        gsap.to('.button__about', { autoAlpha: 1, zIndex: 1001, duration: 0.5, delay: 2, ease: "power3.inOut" })

        //fade out

        gsap.to('.button__back', { autoAlpha: 0, duration: 2, pointerEvents: 'none', /* y: -10, */ delay: 0.5, ease: "power3.inOut" })
        gsap.to('.button__buy', { autoAlpha: 0, duration: 2, pointerEvents: 'none', /* y: -10, */ delay: 0.5, ease: "power3.inOut" })

        // gsap.to('.button__buy', { autoAlpha: 0, duration: 2, pointerEvents: 'none', y: -10, delay: 0.5, ease: "power3.inOut" })

        // gsap.to('.compilation__title', { autoAlpha: 0, duration: 2, ease: "power3.inOut" })
        // gsap.to('.about__title', { autoAlpha: 0, duration: 2, ease: "power3.inOut" })
        gsap.to('.about__container', { autoAlpha: 0, pointerEvents: "none", duration: 2, ease: "power3.inOut", })
        gsap.to('.compilation__container', { autoAlpha: 0, pointerEvents: "none", duration: 2, ease: "power3.inOut", })

        //pointer eents

        // gsap.to('.menu', { pointerEvents: "auto" })
        gsap.to('canvas', { pointerEvents: 'auto' })

        setTimeout(() => {
            navigate('/');
        }, 2500);
    };



    const { t } = useTranslation();

    return (

        <div className="navi button__back" onClick={handleClick}>
            <p>{t('back-button')}</p>
        </div>


    )
}

export default ButtonBack;